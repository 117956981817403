// src/components/Equipe.jsx

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faHandsHelping, faRecycle } from '@fortawesome/free-solid-svg-icons';
import Lottie from 'react-lottie'; // Certifique-se de que react-lottie está instalado
import scrollAnimation from '../../assets/lottie/scrollAnimation.json'; // Atualize o caminho conforme necessário
import { useInView } from 'react-intersection-observer'; // Importando o hook
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../Home/Footer';
import Missao from './Missao';
import Padarias from './Padarias';

const letterAnimation = {
  hidden: { opacity: 0, y: 50 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.05, // Delay entre as letras
    },
  }),
};

const fadeInLeft = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0 },
};

const fadeInRight = {
  hidden: { opacity: 0, x: 100 }, // Começa invisível e deslocado para a direita
  visible: { opacity: 1, x: 0 },  // Fica visível e se move para a posição inicial
};


const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};




const Equipe = () => {

  const { ref: quemSomosRef, inView: isQuemSomosInView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const { ref: missaoRef, inView: isMissaoInView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const { ref: servicosRef, inView: isServicosInView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const { ref: clientesRef, inView: isClientesInView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const { ref: inovacaoRef, inView: isInovacaoInView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const { ref: sobreRef, inView: isSobreInView } = useInView({ triggerOnce: true, threshold: 0.2 });

  const sectionRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("is-visible");
          } else {
            entry.target.classList.remove("is-visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const section = sectionRef.current;
    if (section) observer.observe(section);

    return () => {
      if (section) observer.unobserve(section);
    };
  }, []);

  const title = "Sobre";

  const values = [
    {
      title: 'Compromisso com a Qualidade',
      description: 'Nossos sistemas de refrigeração são desenvolvidos com os mais altos padrões de qualidade, garantindo desempenho superior e durabilidade.',
      icon: faLightbulb // Ícone de inovação
    },
    {
      title: 'Inovação e Sustentabilidade',
      description: 'Buscamos continuamente soluções inovadoras que minimizem o impacto ambiental, utilizando tecnologias que promovem eficiência energética e sustentabilidade.',
      icon: faRecycle // Ícone de sustentabilidade
    },
    {
      title: 'Atendimento Personalizado',
      description: 'Cada cliente é único. Oferecemos consultoria e suporte personalizados para garantir que cada projeto atenda perfeitamente às necessidades específicas.',
      icon: faHandsHelping // Ícone de atendimento
    }
  ];

  const services = [
    {
      title: 'Qualidade do Início ao Fim',
      description: 'Mantemos o controle total do processo de fabricação, do começo ao fim, para assegurar que cada sistema de refrigeração entregue esteja alinhado aos mais altos padrões de qualidade.',
      image: 'images/image/img1.jpg',
      altText: 'Qualidade do Início ao Fim'
    },
    {
      title: 'Consultoria e Projetos Personalizados',
      description: 'Oferecemos soluções sob medida para atender as demandas específicas de cada cliente, garantindo que cada projeto de refrigeração seja otimizado para suas necessidades.',
      image: 'images/image/img1.jpg',
      altText: 'Consultoria e Projetos Personalizados'
    },
    {
      title: 'Manutenção Preventiva e Corretiva',
      description: 'Nossos serviços de manutenção garantem que seus sistemas de refrigeração estejam sempre funcionando com a máxima eficiência, evitando problemas futuros e custos desnecessários.',
      image: 'images/image/img1.jpg',
      altText: 'Manutenção Preventiva e Corretiva'
    }
  ];






  const [currentSection, setCurrentSection] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);


  const scrollToHistoria = () => {
    const section = document.getElementById('historia');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setCurrentSection('historia');
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: scrollAnimation, // Seu arquivo de animação Lottie
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const fadeInVariants = {
    left: {
      hidden: { opacity: 0, x: -100 }, // Vem da esquerda
      visible: {
        opacity: 1,
        x: 0,
        transition: {
          duration: 1, // Ajuste para uma entrada suave (aumentar se precisar mais lenta)
          ease: "easeOut", // Suavidade
        },
      },
    },
    center: {
      hidden: { opacity: 0, scale: 0.8 }, // Começa pequeno no centro
      visible: {
        opacity: 1,
        scale: 1,
        transition: {
          duration: 1, // Ajuste para uma entrada suave
          ease: "easeOut",
        },
      },
    },
    right: {
      hidden: { opacity: 0, x: 100 }, // Vem da direita
      visible: {
        opacity: 1,
        x: 0,
        transition: {
          duration: 1, // Ajuste para uma entrada suave
          ease: "easeOut",
        },
      },
    },
  };

  return (
    <Container>
      {/* Seção "História da Empresa" */}
      <Section2 id="historia">
        <motion.div
          style={{
            position: 'relative',
            backgroundImage: `url('images/image/Servicos/webtop.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '45vh', // Altura padrão para desktops
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            textAlign: 'center',
          }}
          initial={{ opacity: 0, scale: 1.2 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.2 }}
        >
          {/* Overlay escuro */}
          <motion.div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              zIndex: 1,
            }}
          ></motion.div>

          {/* Texto Responsivo */}
          <motion.div
            style={{ zIndex: 2 }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <ResponsiveTitle>Sobre Nós</ResponsiveTitle>
          </motion.div>

        </motion.div>
      </Section2>


      {/* Seção "Quem Somos" */}
      {/* Seção "Quem Somos" */}
      <Section id="quem-somos" ref={quemSomosRef}>

        <SectionTitle>
          <motion.img
            src="/images/logo/menu.png"
            alt="Logo"
            style={{ width: '50px', height: '50px' }}
            initial="hidden"
            animate={isQuemSomosInView ? "visible" : "hidden"}
            variants={fadeInLeft}
            transition={{ duration: 0.9, ease: "easeOut" }}
          />
          <motion.h1
            initial="hidden"
            animate={isQuemSomosInView ? "visible" : "hidden"}
            variants={fadeIn}
            transition={{ duration: 1, ease: "easeOut", delay: 0.8 }}
          >
            Quem Somos
          </motion.h1>
        </SectionTitle>

        <QuemSomosContainer>
          {/* Imagem do lado esquerdo */}
          <ImageContainer
            initial="hidden"
            animate={isQuemSomosInView ? "visible" : "hidden"}
            variants={fadeInFromLeft}
            transition={{ duration: 1, ease: "easeOut" }}
          >
            <img src="/images/menu_images/quemsomos.jpg" alt="Nossa Missão" />
          </ImageContainer>

          {/* Texto à direita com título acima da descrição */}
          <TextContainer>
            <TitleText
              initial="hidden"
              animate={isQuemSomosInView ? 'visible' : 'hidden'}
              variants={fadeIn}
              transition={{ duration: 1, delay: 0.5 }}
            >
              Sobre
            </TitleText>

            <MissionText
              initial="hidden"
              animate={isQuemSomosInView ? 'visible' : 'hidden'}
              variants={fadeIn}
              transition={{ duration: 1, delay: 0.5 }}
            >
              Nossa <strong style={{ fontFamily: 'Brmalls' }}>missão</strong> é oferecer sistemas de <span style={{ color: '#354A81', fontFamily: 'Brmalls', fontWeight: 'bold' }}>refrigeração</span> com alta qualidade e soluções personalizadas, promovendo um ambiente <strong style={{ fontFamily: 'Brmalls' }}>sustentável</strong> e garantindo <span style={{ fontFamily: 'Brmalls', fontWeight: 'bold' }}>eficiência energética</span>.

            </MissionText>
          </TextContainer>
        </QuemSomosContainer>



      </Section>

      {/* Seção "Nossos Valores" */}
      <SectionM id="missao" ref={missaoRef}>
        <RightAlignedTitle>
          <motion.img
            src="/images/logo/menu.png"
            alt="Logo"
            style={{ width: '50px', height: '50px' }}
            initial="hidden"
            animate={isMissaoInView ? "visible" : "hidden"}
            variants={fadeInRight}
            transition={{ duration: 0.9, ease: "easeOut" }}
          />
          <motion.h1
            initial="hidden"
            animate={isMissaoInView ? "visible" : "hidden"}
            variants={fadeIn}
            transition={{ duration: 1, ease: "easeOut", delay: 0.8 }}
          >
            Nossos Valores
          </motion.h1>
        </RightAlignedTitle>

        {/* Texto animado */}
        <motion.h2
          style={{
            fontSize: '2.7rem',
            fontWeight: 'bold',
            marginBottom: '50px',
            marginTop: '50px',
            color: '#000',
            textAlign: 'center',
            fontFamily: 'Brmalls'
          }}
          initial="hidden"
          animate={isMissaoInView ? "visible" : "hidden"}
          variants={fadeInFromTop}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          Porque escolher a AER?
        </motion.h2>


        {/* Conteúdo da seção "Valores" */}
        <ValoresContainer ref={missaoRef}>
          {values.map((value, index) => {
            const animationVariant = index % 3 === 0 ? "left" : index % 3 === 1 ? "center" : "right";

            return (
              <Valor
                key={index}
                initial="hidden"
                animate={isMissaoInView ? "visible" : "hidden"} // Conecta com o estado inView
                whileHover={{
                  scale: 1.03, // Efeito de hover
                  translateY: -5,
                }}
                variants={fadeInVariants[animationVariant]}
                transition={{
                  duration: 0.2, // Transição rápida ao passar o mouse
                  ease: "easeOut", // Suavidade no hover
                }}
              >
                <FontAwesomeIcon icon={value.icon} />
                <h3>{value.title}</h3>
                <p>{value.description}</p>
              </Valor>

            );
          })}
        </ValoresContainer>

      </SectionM>

      {/* Seção "Serviços" */}
      {/* <Section id="servicos" ref={servicosRef}>
        <SectionTitle>
          <motion.img
            src="/images/logo/menu.png"
            alt="Logo"
            style={{ width: '40px', height: '40px' }}
            initial="hidden"
            animate={isServicosInView ? "visible" : "hidden"}
            variants={fadeInLeft}
            transition={{ duration: 0.9, ease: "easeOut" }}
          />

          <motion.h1
            initial="hidden"
            animate={isServicosInView ? "visible" : "hidden"}
            variants={fadeIn}
            transition={{ duration: 1, ease: "easeOut", delay: 0.8 }}
          >
            Serviços
          </motion.h1>
        </SectionTitle>

        <ServiceContainer>
          {services.map((service, index) => (
            <ServiceItem key={index} reverse={index % 2 !== 0}>
              <ServiceText>
                <ServiceSubtitle>THE NATURAL CHOICE</ServiceSubtitle>
                <h3>{service.title}</h3>
                <Underline />
                <p>{service.description}</p>
              </ServiceText>
              <ServiceImage>
                <img src={service.image} alt={service.altText} />
              </ServiceImage>
            </ServiceItem>
          ))}
        </ServiceContainer>
      </Section> */}

      <Missao />

      {/* Seção "Clientes e Parcerias" */}
      <FooterSectionWrapper>
        <SectionC id="clientes" ref={clientesRef}>
          <SectionTitle>
            <motion.img
              src="/images/logo/menu.png"
              alt="Logo"
              style={{ width: '40px', height: '40px' }}
              initial="hidden"
              animate={isClientesInView ? "visible" : "hidden"}
              variants={fadeInLeft}
              transition={{ duration: 0.9, ease: "easeOut" }}
            />

            <motion.h1
              initial="hidden"
              animate={isClientesInView ? "visible" : "hidden"}
              variants={fadeIn}
              transition={{ duration: 1, ease: "easeOut", delay: 0.8 }}
            >
              Clientes
            </motion.h1>
          </SectionTitle>
          <SectionContainer ref={sectionRef}>
            <Title>Padarias</Title>
            <Title2>Conheça Nossos Clientes:</Title2>
            <Padarias />
          </SectionContainer>
        </SectionC>
        <Footer />
      </FooterSectionWrapper>
    </Container>
  );
};

// Styled Components
const fadeInFromLeft = {
  hidden: { opacity: 0, x: -100 }, // Começa invisível e deslocado para a esquerda
  visible: { opacity: 1, x: 0 },   // Fica visível e se move para a posição inicial
};

const fadeInFromTop = {
  hidden: { opacity: 0, y: -50 }, // Começa invisível e deslocado para cima
  visible: { opacity: 1, y: 0 },  // Fica visível e se move para a posição final
};



const Container = styled.div`
  font-family: 'Arial, sans-serif';
  color: #000;
  position: relative;
`;

const FooterSectionWrapper = styled.div`
  margin-top: -25%; // Ajuste o espaçamento no topo
`;

// Estilos para o menu com animações
const PageMenu = styled.div`
  position: fixed;
  top: 50%;
  left: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  transform: translateY(-50%);
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

const MenuDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: #999;
  border-radius: 50%;
  position: relative;

  &.active {
    background-color: #fff;
  }
`;

const MenuText = styled(motion.span)`
  margin-left: 10px;
  color: #999;
  font-family: 'Arial, sans-serif';

  &.active {
    color: #fff;
  }
`;

const Section = styled.section`
  min-height: 60vh;
  margin: 0;
  padding: 40px 50px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionC = styled.section`
  margin: 0; // Remove margem externa
  padding: 20px 50px; // Ajuste o padding conforme necessário
  text-align: center;

  @media (max-width: 768px) {
    padding: 10px 20px; // Menor espaçamento para telas pequenas
  }
`;


const SectionM = styled.section`
  min-height: 80vh;
  margin: 0;
  padding: 40px 50px;
  text-align: center;
  margin-top: 40px;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const QuemSomosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 40px;  
  gap: 600px;
  margin-right: 300px;

  @media (max-width: 768px) {
    align-items: center; 
    text-align: center; 
  }
`;


const Section2 = styled.section`
  position: relative;
  width: 100%;
  height: 45vh; /* Altura padrão */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  /* Estilos responsivos */
  @media (max-width: 1024px) {
    height: 35vh; /* Altura menor para tablets */
    h1 {
      font-size: 3rem; /* Fonte menor */
    }
  }

  @media (max-width: 768px) {
    height: 30vh; /* Altura menor para celulares */
    h1 {
      font-size: 1rem; /* Texto reduzido no celular */
    }
  }

  @media (max-width: 480px) {
    height: 25vh; /* Altura para dispositivos pequenos */
    h1 {
      font-size: 1rem; /* Texto reduzido no celular */
    }
  }
`;

const ResponsiveTitle = styled.h1`
  font-size: 4rem !important;
  margin-bottom: 20px !important;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7) !important;

  @media (max-width: 1024px) {
    font-size: 3.5rem !important;
  }

  @media (max-width: 768px) {
    font-size: 3rem !important;
  }

  @media (max-width: 480px) {
    font-size: 2.5rem !important;
    margin-top: 100px;
  }
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  img {
    z-index: 1;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 20px;
    z-index: 1;
  }

  @media (max-width: 768px) {
    display: none; /* Esconde o título e o ícone no modo responsivo */
  }
`;


const RightAlignedTitle = styled(SectionTitle)`
  justify-content: flex-start; /* Alinha o conteúdo à direita */
  flex-direction: row-reverse; /* Ícone à direita e texto à esquerda */
  padding-right: 20px; /* Adiciona espaçamento da borda direita */

  h1 {
    font-size: 1.5rem;
    font-weight: bold;  
    margin-right: 20px;
  }

  img {
    width: 40px;
    height: 40px;
  }
`;


const ClientContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TextContainer = styled.div`
  max-width: 45%;
  h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-top: 15px;
  }

  @media (max-width: 768px) {
    max-width: 90%; /* Ocupar quase toda a largura em dispositivos menores */
    text-align: center; /* Centralizar o texto no mobile */
    margin: 0 auto; /* Centralizar no meio */
  }

  @media (max-width: 480px) {
    max-width: 100%; /* Ocupar toda a largura em telas muito pequenas */
    text-align: center;
    padding: 0 10px; /* Adicionar padding nas laterais */
  }
`;


const ValoresContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 50px;

  @media (max-width: 768px) {
    padding: 20px;
    flex-direction: column;
    gap: 15px;
  }
`;

const Valor = styled(motion.div)`
  width: 30%;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.3s ease;

  svg {
    font-size: 50px;
    color: #0044cc;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    font-family: 'Brmalls';
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
    font-family: 'Brmalls';
  }

  @media (max-width: 768px) {
    width: 100%;

    svg {
      font-size: 40px;
    }

    h3 {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.9rem;
    }
  }
`;



const ServiceItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;




const SectionContainer = styled.div`
  padding: 60px 20px;
  background-color: #fff;
  color: #333;
  text-align: center;
  opacity: 0;
  transform: translateY(20px); /* Efeito inicial */
  transition: opacity 1s ease-out, transform 1s ease-out;

  &.is-visible {
    opacity: 1;
    transform: translateY(0); /* Quando visível, volta para posição original */
  }

  /* Ajustes para dispositivos menores */
  @media (max-width: 768px) {
    padding: 30px 10px;
    margin-top: -550px; /* Sobe ainda mais em dispositivos muito pequenos */
  }

  @media (max-width: 480px) {
    padding: 20px 5px;
    margin-top: -500px; /* Sobe ainda mais em dispositivos muito pequenos */
  }
`;


const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 80px;
  color: #333;
  text-transform: uppercase;
  font-family: 'Brmalls';
  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 50px;
  }
`;


const Title2 = styled.h2`
  font-size: 1.2rem;
  margin-top: -50px;
  color: #8d97ad;
  font-family: 'Brmalls'; /* Mantém a fonte */
  font-weight: 300; /* Diminui a grossura do texto */
  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 50px;
  }
`;


const TitleText = styled(motion.h1)`
  font-size: 2.5rem; /* Tamanho padrão */
  font-weight: bold;
  margin-bottom: 15px; /* Espaçamento entre o título e o texto */
  line-height: 1.2;
  text-align: center; /* Centraliza o texto */
  margin-right: 258px;
  @media (max-width: 768px) {
    font-size: 2rem; /* Reduz o tamanho para tablets */
  }

  @media (max-width: 480px) {
    font-size: 2.8rem; /* Fonte menor em telas muito pequenas */
    margin-top: 120px;
    margin-left: -228px;
  }
`;



const MissionText = styled(motion.p)`
  font-size: 1rem;
  max-width: 400px; /* Limita a largura do texto para quebrar linhas */
  line-height: 1.5; /* Define o espaçamento entre as linhas */
  text-align: left; /* Alinha o texto à direita */
  margin-top: 10px;
  font-family: 'Brmalls';
  @media (max-width: 768px) {
    font-size: 0.9rem; /* Reduz o tamanho do texto no mobile */
    min-width: 900%; /* Ocupa quase toda a largura no mobile */
    text-align: center; /* Centraliza o texto no mobile */
    margin-left: -290px;
  }
`;

const ImageContainer = styled(motion.div)`
  flex: 1;
  max-width: 350px;
  position: relative;
  left: 300px;
  transform: translateY(-20px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4); 
  overflow: hidden; 

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;




export default Equipe;
