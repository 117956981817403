import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ExpositoresSection from './Products/ExpositoresSection';
import Footer from '../../../Screens/Home/Footer';

const FrenteLoja = () => {
    const carouselImages = [
        { src: "/images/image/Servicos/frente-loja3.jpg", alt: "Imagem 1" },
        { src: "/images/image/Servicos/frente-loja2.jpg", alt: "Imagem 2" },
        { src: "/images/image/Servicos/frente-loja4.jpg", alt: "Imagem 3" },
        { src: "/images/image/Servicos/frente-loja5.jpg", alt: "Imagem 4" },
        { src: "/images/image/Servicos/frente-loja6.jpg", alt: "Imagem 5" },
        { src: "/images/image/Servicos/frente-loja1.jpg", alt: "Imagem 6" },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        centerMode: true,
        centerPadding: "10px",
    };

    const variants = {
        hidden: { opacity: 0, y: 50 }, // Estado inicial
        visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } }, // Estado final
    };

    const variantsText = {
        hidden: { opacity: 0, y: 50 }, // Inicial: invisível e deslocado para baixo
        visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } }, // Final: visível e na posição
    };

    const variantsImage = {
        hidden: { opacity: 0 }, // Inicial: invisível
        visible: { opacity: 1, transition: { duration: 1.2, ease: 'easeIn' } }, // Final: visível
    };

    const imageVariants = {
        hidden: { opacity: 0, scale: 0.95 }, // Inicial: invisível e levemente menor
        visible: { opacity: 1, scale: 1, transition: { duration: 1, ease: 'easeOut' } }, // Final: visível
    };


    return (
        <PageContainer>
            <Section2
                as={motion.section}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
            >
                {/* Adicionando animação ao fundo */}
                <BackgroundImage
                    as={motion.div}
                    variants={imageVariants}
                    initial="hidden"
                    animate="visible"
                    style={{ backgroundImage: "url('/images/image/Servicos/servicos.jpg')" }}
                >
                    <Overlay />
                    <Content>
                        <Title>Serviços</Title>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <StyledLink to="/servicos">Serviços</StyledLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator>{'>'}</BreadcrumbSeparator>
                            <BreadcrumbItem active>Frente de Loja</BreadcrumbItem>
                        </Breadcrumb>
                    </Content>
                </BackgroundImage>
            </Section2>

            <ContentSection>
                <TextContainer
                    as={motion.div}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    variants={variantsText}
                >
                    <Location>Frente de Loja</Location>
                    <MainTitle>Exposição de Produtos</MainTitle>
                    <ActionButton>Saiba Mais</ActionButton>
                </TextContainer>
                <ImageContainer
                    as={motion.div}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    variants={variantsImage}
                >
                    <LargeImage src="/images/image/Servicos/frente-loja.jpg" alt="Frente de Loja" />
                </ImageContainer>
            </ContentSection>

            <CarouselSection>
                <Slider {...settings}>
                    {carouselImages.map((image, index) => (
                        <div key={index}>
                            <CarouselImageContainer>
                                <CarouselImage src={image.src} alt={image.alt} />
                            </CarouselImageContainer>
                        </div>
                    ))}
                </Slider>
            </CarouselSection>

            <ExpositoresSection tipo="frente-loja" />
            <Footer />
        </PageContainer>
    );
};

export default FrenteLoja;

// Estilos corrigidos e com responsividade

const PageContainer = styled.div`
    font-family: Arial, sans-serif;
`;

const Section2 = styled(motion.section)`
    position: relative;
`;

const BackgroundImage = styled(motion.div)`
  position: relative;
  background-image: url('images/image/Servicos/servicos.jpg');
  background-size: cover;
  background-position: center;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
`;

const Content = styled.div`
    position: relative;
    z-index: 2;
    text-align: center;
    color: #fff;
`;

const Title = styled.h1`
    font-size: 4rem;
    color: #fff;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);

    @media (max-width: 768px) {
        font-size: 2.5rem;
    }
`;

const Breadcrumb = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #fff;
`;

const BreadcrumbItem = styled.span`
    color: ${(props) => (props.active ? '#354A81' : '#fff')};
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
`;

const BreadcrumbSeparator = styled.span`
    margin: 0 5px;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #fff;

    &:hover {
        text-decoration: underline;
    }
`;

const ContentSection = styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 150px;
    gap: 10px;
    margin-left: 80px;
     @media (max-width: 768px) {
        flex-direction: column; /* Alinha os itens em coluna para dispositivos menores */
        align-items: center; /* Garante a centralização vertical mesmo em dispositivos menores */
        justify-content: center; /* Garante a centralização horizontal */
        padding: 40px;
        margin-left: 0; /* Remova o deslocamento para centralizar completamente */
    }
`;

const TextContainer = styled.div`
    flex: 1;
    text-align: left;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

const Location = styled.p`
    font-size: 1rem;
    color: #888;
    margin-bottom: 5px;
    margin-left: 5px;
`;

const MainTitle = styled.h2`
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
     font-family: 'Brmalls';
    @media (max-width: 768px) {
        font-size: 1.8rem;
    }
`;

const ActionButton = styled.button`
    background-color: #354A81;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
     font-family: 'Brmalls';
    &:hover {
        background-color: #2a3a61;
    }

    @media (max-width: 768px) {
        font-size: 0.9rem;
        padding: 8px 16px;
    }
`;

const ImageContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

const LargeImage = styled.img`
    width: 100%;
    max-width: 700px;
    border-radius: 2px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

const CarouselSection = styled.section`
    padding: 40px 0;
    
    .slick-list {
        margin: 0 -10px; /* Garante que o espaçamento não corte na borda */
    }

    .slick-prev, .slick-next {
        color: #354A81 !important;
    }

    .slick-dots li button:before {
        color: #354A81 !important;
        margin-top: 20px; 
    }

    @media (max-width: 768px) {
        .slick-prev, .slick-next {
            display: none;
        }
    }
`;

const CarouselImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 10px; /* Adiciona espaçamento horizontal entre as imagens */

    @media (max-width: 768px) {
        margin: 0 5px; /* Reduz o espaçamento para telas menores */
    }
`;

const CarouselImage = styled.img`
    width: 100%;
    max-width: 450px;
    height: 250px;
    border-radius: 2px;
    object-fit: cover;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);

    @media (max-width: 768px) {
        max-width: 300px;
        height: 200px;
    }

    @media (max-width: 480px) {
        max-width: 250px;
        height: 150px;
    }
`;

