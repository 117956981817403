import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes, css } from "styled-components";

const ModalidadesSection = () => {
  const modalidades = [
    { image: "/images/menu_images/1.jpg", title: "Manutenção Preventiva" },
    { image: "/images/menu_images/sobrenos.jpg", title: "Venda de Equipamentos" },
    { image: "/images/menu_images/2.jpg", title: "Projetos Personalizados" },
  ];

  const [isVisible, setIsVisible] = useState(false); // Para Header e logo
  const [visibleCards, setVisibleCards] = useState([]); // Para os cards
  const sectionRef = useRef(null);
  const cardsRef = useRef([]);

  // Observador para o Header e logo
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Marca a seção como visível
        }
      },
      { threshold: 0.2 } // Dispara quando 20% do elemento é visível
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) observer.unobserve(sectionRef.current);
    };
  }, []);

  // Observador para os cards
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.dataset.index);
            setVisibleCards((prev) => [...new Set([...prev, index])]); // Marca o card como visível
          }
        });
      },
      { threshold: 0.2 } // Dispara quando 20% do card está visível
    );

    if (cardsRef.current) {
      cardsRef.current.forEach((card) => {
        if (card) observer.observe(card);
      });
    }

    return () => {
      if (cardsRef.current) {
        cardsRef.current.forEach((card) => {
          if (card) observer.unobserve(card);
        });
      }
    };
  }, []);

  return (
    <SectionContainer ref={sectionRef}>
      <VerticalText>Refrigeração Ideal</VerticalText>
      <Logo src="/images/menu_images/ursoazul.png" alt="Logotipo" isVisible={isVisible} />
      <Header>
        <TitleWrapper isVisible={isVisible}>
        <Title isVisible={isVisible}>Nossas Soluções</Title>
        </TitleWrapper>
        <Description isVisible={isVisible}>
          Temos como missão oferecer as melhores soluções em refrigeração
          comercial e industrial, ajudando nossos clientes a atingirem seus
          objetivos com máxima eficiência. Confira:
        </Description>
      </Header>

      <Grid>
        {modalidades.map((modalidade, index) => (
          <Card
            key={index}
            ref={(el) => (cardsRef.current[index] = el)}
            data-index={index}
            isVisible={visibleCards.includes(index)}
            delay={index * 0.2} // Atraso de 0.2s entre cada card
          >
            <Image src={modalidade.image} alt={modalidade.title} />
            <CardTitle>{modalidade.title}</CardTitle>
          </Card>
        ))}
      </Grid>
    </SectionContainer>
  );
};

export default ModalidadesSection;

// Animação de entrada para os cards
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px); /* Começa deslocado para baixo */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Volta para a posição original */
  }
`;

const fadeInAndSlide = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px); /* Começa ligeiramente acima */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Volta à posição original */
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fillUp = keyframes`
  from {
    transform: scaleY(0); /* Começa com altura 0 */
    transform-origin: bottom; /* Origem da animação é a base */
  }
  to {
    transform: scaleY(1); /* Preenche completamente */
    transform-origin: bottom;
  }
`;

// Styled Components

const SectionContainer = styled.section`
  background-color: #000;
  color: #fff;
  padding: 50px 20px;
  position: relative;
  
`;

const Header = styled.div`
  display: flex; /* Alinha os elementos lado a lado */
  align-items: center;
  justify-content: center;
  gap: 20px; /* Espaçamento entre o título e o texto */
  height: 40vh; /* Reduz a altura para subir os elementos */
  margin-right: 700px;
  margin-top: -80px; 

  @media (max-width: 768px) {
    flex-direction: column; /* Volta para coluna em telas menores */
    height: auto; /* Ajusta a altura automaticamente */
    margin-right: 0; /* Remove o deslocamento horizontal para telas menores */
    margin-top: -30px; /* Ajuste menor em dispositivos móveis */
    padding: 0 20px; /* Adiciona um espaçamento horizontal */
  }

  @media (max-width: 480px) {
    margin-top: -20px; /* Ajuste ainda menor para celulares */
    padding: 0 10px; /* Diminui o espaçamento horizontal em celulares */
  }
`;




const TitleWrapper = styled.div`
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  z-index: 1;
  text-align: left;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    height: 100%;
    background-color: #354A81; // Cor azul
    transform: scaleY(0); // Inicialmente invisível
    transform-origin: bottom; // Cresce de baixo para cima
    z-index: 0; // Padrão (atrás do conteúdo do wrapper)
    ${({ isVisible }) =>
      isVisible &&
      css`
        animation: ${fillUp} 0.7s ease-in-out;
        animation-delay: 0.3s;
        animation-fill-mode: forwards;
      `}
  }

  @media (max-width: 768px) {
    &::after {
     top: 0;
      left: 0;
    }
  }
`;




const BlueLine = styled.div`
  width: 10px; /* Largura do traço */
  height: 100px; /* Altura do traço */
  background-color: #354A81; /* Cor azul */
  border-radius: 0 0 10px 10px; /* Arredondamento na parte inferior */
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: #354A81; /* Cor azul */
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%); /* Triângulo invertido */
  }
`;


const Title = styled.h1`
  font-size: 2.2rem; /* Tamanho do texto */
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
  font-family: 'Brmalls';
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) => (isVisible ? "translateY(0)" : "translateY(20px)")};
  ${({ isVisible }) =>
    isVisible &&
    css`
      animation: ${fadeIn} 1s ease-in-out;
    `}
  transition: opacity 0.5s ease, transform 0.5s ease;

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Reduz apenas o tamanho da fonte */
    text-align: center; /* Mantém o texto centralizado */
  }

  @media (max-width: 480px) {
    font-size: 1.2rem; /* Ajusta ainda mais o tamanho da fonte para celulares */
    text-align: center; /* Centraliza o texto */
  }
`;

const Description = styled.p`
  font-size: 0.8rem;
  color: #fff;
  max-width: 40%;
  line-height: 1.4;
  position: relative;
  z-index: 2;
  text-align: left; /* Alinha o texto à esquerda */
  margin-left: 0; /* Garante que siga o alinhamento à esquerda */
  font-family: 'Brmalls';
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) => (isVisible ? "translateY(0)" : "translateY(20px)")};
  ${({ isVisible }) =>
    isVisible &&
    css`
      animation: ${fadeIn} 1.5s ease-in-out;
    `}
  transition: opacity 0.5s ease, transform 0.5s ease;

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Aumenta ligeiramente a fonte em telas médias */
    max-width: 90%; /* Permite que o texto ocupe mais espaço */
    text-align: center; /* Centraliza o texto para telas menores */
  }

  @media (max-width: 480px) {
    font-size: 0.8rem; /* Mantém o tamanho de texto pequeno */
    max-width: 100%; /* Usa toda a largura da tela */
    text-align: center; /* Centraliza o texto */
  }
`;


const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Mantém três colunas */
  row-gap: 40px; /* Espaçamento entre as linhas (de cima para baixo) */
  column-gap: 10px; /* Espaçamento entre colunas */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  padding: 20px; /* Adiciona espaçamento ao redor da grid */

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Duas colunas em telas menores */
    row-gap: 30px; /* Ajusta o espaçamento entre linhas para telas menores */
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; /* Apenas uma coluna em telas pequenas */
    row-gap: 30px; /* Espaçamento menor entre linhas */
      margin-top: 30px;
  }
`;


const Card = styled.div`
  position: relative;
  overflow: hidden; /* Garante que elementos internos não escapem */
  transition: transform 0.3s ease; /* Suaviza o zoom */
  height: 280px;
  width: 70%; /* Usa 70% da largura disponível */
  margin: 0 auto; /* Centraliza o card no grid */
  opacity: 0; /* Começa invisível */
  transform: ${({ isVisible }) => (isVisible ? "translateY(0)" : "translateY(50px)")};

  ${({ isVisible, delay }) =>
    isVisible &&
    css`
      animation: ${fadeInUp} 0.8s ease-in-out forwards;
      animation-delay: ${delay}s; /* Atraso baseado no índice */
    `}

&:hover {
    transform: scale(1.05); /* Efeito de zoom ao passar o mouse */
  }

  @media (max-width: 768px) {
    height: 150px; /* Reduz a altura para telas menores */
    width: 90%; /* Usa mais largura para preencher melhor */
  }

  @media (max-width: 480px) {
    height: 200px; /* Altura para celulares */
    width: 100%; /* Usa 100% da largura disponível */
  }
`;



const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%) brightness(0.2); /* Adiciona tons de cinza e ajusta o brilho */
  transition: transform 0.3s ease, filter 0.3s ease;

  &:hover {
    filter: grayscale(0%) brightness(1); /* Remove o cinza e aumenta ainda mais o brilho no hover */
    transform: scale(1.1); /* Adiciona um leve zoom */
  }

  @media (max-width: 768px) {
    filter: grayscale(0%) brightness(1); /* Sempre colorida e com brilho normal no mobile */
    &:hover {
      filter: none; /* Desativa o hover no mobile */
    }
  }
`;



const CardTitle = styled.div`
  position: absolute;
  bottom: 20px; /* Ajuste de posição */
  left: 20px;
  background-color: transparent; /* Sem fundo */
  color: #fff; /* Cor branca do texto */
  font-size: 0.9rem; /* Tamanho da fonte */
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Brmalls';
  z-index: 10; /* Garante que o texto fique na frente */

  @media (max-width: 768px) {
    bottom: 50%; /* Centraliza verticalmente */
    left: 50%; /* Centraliza horizontalmente */
    transform: translate(-50%, 50%); /* Ajusta o deslocamento */
    text-align: center; /* Centraliza o texto */
    font-size: 0.8rem; /* Ajusta o tamanho em telas menores */
    width: 80%; /* Garante que o texto ocupe espaço centralizado */
  }

  @media (max-width: 480px) {
    bottom: 15%; /* Centraliza verticalmente */
    left: 50; /* Centraliza horizontalmente */
    transform: translate(-50%, 50%); /* Ajusta o deslocamento */
    text-align: center; /* Centraliza o texto */
    font-size: 0.7rem; /* Ajusta o tamanho para celulares */
    width: 90%; /* Garante espaço adequado em celulares */
  }
`;


const Logo = styled.img`
  position: absolute;
  top: -30px;
  right: 50px;
  width: 20vw;
  max-width: 500px;
  z-index: 10;
  transform: translateY(-20px);
  opacity: 0;

  ${({ isVisible }) =>
    isVisible &&
    css`
      animation: ${fadeInAndSlide} 1s ease-in-out;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
    `}

  @media (max-width: 768px) {
    display: none; /* Oculta a logo em telas menores */
  }
`;

const VerticalText = styled.div`
  position: absolute;
  bottom: 35%;
  right: 25px;
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  text-orientation: mixed;
  white-space: nowrap;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.3);
  z-index: 1000;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.8rem;

  &::before {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    width: 80%;
    height: 0.5px;
    background-color: rgba(255, 255, 255, 0.3);
  }

  @media (max-width: 768px) {
    display: none; /* Oculta o texto vertical em telas menores */
  }
`;


