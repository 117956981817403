import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import 'react-vertical-timeline-component/style.min.css';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FaTools, FaSnowflake, FaLaptopCode, FaStore } from 'react-icons/fa';

const Missao = () => {
  // Configuração da animação
  const fadeInVariant = {
    hidden: { opacity: 0, y: 50 }, // Começa invisível e deslocado para baixo
    visible: { opacity: 1, y: 0, transition: { duration: 1 } }, // Fica visível e sobe
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Define responsividade para telas menores que 768px
    };

    // Executa ao montar e ao redimensionar
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [clipPathStyle, setClipPathStyle] = useState(styles.pageContainer.clipPath);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setClipPathStyle('polygon(50% 0%, 100% 6%, 100% 60%, 100% 100%, 0 100%, 0% 60%, 0 6%)');
      } else {
        setClipPathStyle(styles.pageContainer.clipPath); // Voltar para o padrão
      }
    };

    // Atualiza na montagem
    handleResize();

    // Atualiza ao redimensionar
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ ...styles.pageContainer, clipPath: clipPathStyle }}>
      <motion.header
        style={styles.header}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }} // Dispara a animação quando 20% do elemento estiver visível
        variants={fadeInVariant} // Variantes de animação
      >
        <h1 style={styles.heading}>Nossa Jornada</h1>
        <p style={styles.paragraph}>
          Desde o início de nossas atividades, buscamos inovação constante para transformar o mercado de refrigeração.
          Este é um breve resumo de nossos marcos mais significativos ao longo das décadas, culminando em uma nova era de
          inovação em 2024.
        </p>
      </motion.header>

      <main style={styles.main}>
      <div style={{ position: 'relative', width: '100%' }}>
          <VerticalTimeline>
            {/* Início das Atividades */}
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#354A81', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #354A81' }}
              date={<span style={styles.dateStyle}>1990</span>}
              iconStyle={{ background: '#242525', color: '#fff' }}
              icon={<FaTools />}
            >
              <h3
                className="vertical-timeline-element-title"
                style={{ fontFamily: 'Brmalls', fontSize: '1.5em' }}
              >
                Início das Atividades
              </h3>
              <p style={{ fontFamily: 'Brmalls', fontSize: '1.2em' }}>
                Assistência Técnica
              </p>
              <img
                src="/images/menu_images/timeline/aer.jpg"
                alt="Início das atividades"
                style={styles.image}
              />
            </VerticalTimelineElement>


            {/* Câmara Frigorífica */}
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#354A81', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #354A81' }}
              date="1994"
              iconStyle={{ background: '#242525', color: '#fff' }}
              icon={<FaSnowflake />}
            >
              <h3
                className="vertical-timeline-element-title"
                style={{ fontFamily: 'Brmalls', fontSize: '1.5em' }}
              >
                Câmara Frigorífica</h3>
              <p style={{ fontFamily: 'Brmalls', fontSize: '1.2em' }}> Em Painel Modular</p>
              <img
                src="/images/menu_images/timeline/camera.jpeg"
                alt="Câmara Frigorífica"
                style={styles.image}
              />
            </VerticalTimelineElement>

            {/* Sistema de Monitoramento Remoto */}
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#354A81', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #354A81' }}
              date={<span style={styles.dateStyle}>2002</span>}
              iconStyle={{ background: '#242525', color: '#fff' }}
              icon={<FaLaptopCode />}
            >
              <h3
                className="vertical-timeline-element-title"
                style={{ fontFamily: 'Brmalls', fontSize: '1.5em' }}
              >
                Sistema de Monitoramento Remoto</h3>
              <p style={{ fontFamily: 'Brmalls', fontSize: '1.2em' }}>Para Refrigeração</p>
              <img
                src="/images/menu_images/timeline/sistemaremoto.jpeg"
                alt="Sistema de Monitoramento"
                style={styles.image}
              />
            </VerticalTimelineElement>



            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#354A81', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #354A81' }}
              date="2012"
              iconStyle={{ background: '#242525', color: '#fff' }}
              icon={<FaStore />}
            >
              <h3
                className="vertical-timeline-element-title"
                style={{ fontFamily: 'Brmalls', fontSize: '1.5em' }}
              >Linha de Vitrines Refrigeradas</h3>
              <p style={{ fontFamily: 'Brmalls', fontSize: '1.2em' }}>Texto descritivo aqui</p>
              <img
                src="/images/menu_images/timeline/linha.jpg"
                alt="Linha de Vitrines Refrigeradas"
                style={styles.image}
              />
            </VerticalTimelineElement>


            {/* Nova Jornada 2024 */}
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#354A81', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid #354A81' }}
              date={<span style={styles.dateStyle}>2024</span>}
              iconStyle={{ background: '#242525', color: '#fff' }}
              icon={
                <img
                  src="/images/menu_images/urso.png"
                  alt="Nova Jornada"
                  style={{
                    width: '70%',
                    height: '40%',
                    objectFit: 'cover',
                    marginTop: '16px'
                  }}
                />
              }
            >
              <h3
                className="vertical-timeline-element-title"
                style={{ fontFamily: 'Brmalls', fontSize: '1.5em' }}
              >
                Nova Jornada</h3>
              <p style={{ fontFamily: 'Brmalls', fontSize: '1.2em' }}>
                Lançamento de Produtos Inovadores e Sustentáveis</p>
              <img
                src="/images/menu_images/timeline/novajornada.png"
                alt="Nova Jornada"
                style={styles.image}
              />
            </VerticalTimelineElement>

          </VerticalTimeline>
          <div
             style={{
              ...styles.timelineDot,
              left: isMobile ? '6.2%' : '50%', // Ajusta a posição para o lado esquerdo no mobile
              transform: isMobile ? 'translateX(0)' : 'translateX(-50%)', // Remove deslocamento extra no mobile
            }}
          />
        </div>
      </main>
    </div>
  );
};


const styles = {
  pageContainer: {
    background: 'linear-gradient(180deg, rgba(25,25,25,1) 0%, rgba(25,25,25,1) 40%, rgba(58,62,62,0.8) 65%, rgba(255,255,255,0.2) 85%, #fff 100%)',
    minHeight: '370vh',
    color: '#fff',
    clipPath: 'polygon(50% 0%, 100% 10%, 100% 60%, 100% 100%, 0 100%, 0% 60%, 0 10%)', // Default
    position: 'relative',

  },
  timelineDot: {
    width: '12px',
    height: '12px',
    backgroundColor: '#fff',
    borderRadius: '50%',
    position: 'absolute',
    bottom: '0',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: '1',
  },

  header: {
    textAlign: 'center',
    padding: '50px 20px',

  },
  heading: {
    fontSize: '2.5em',
    marginBottom: '20px',
    fontFamily: 'Brmalls',
    marginTop: '140px',
  },
  paragraph: {
    fontSize: '1.2em',
    maxWidth: '800px',
    margin: '0 auto',
    fontFamily: 'Brmalls'
  },
  main: {
    padding: '20px',
    marginTop: '80px',
  },
  image: {
    width: '100%',
    maxWidth: '350px',
    height: 'auto',
    marginTop: '20px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)',
  },
  dateStyle: {
    display: 'block',
    textAlign: 'center', // Centraliza horizontalmente
    fontSize: '16px',
    marginRight: '500px',
    color: '#ccc',
  },
  '@media (max-width: 768px)': {
    header: {
      padding: '30px 10px',
    },
    heading: {
      fontSize: '1.8em',
    },
    paragraph: {
      fontSize: '1em',
      padding: '0 10px',
    },
    image: {
      maxWidth: '200px',
    },
  },
};

export default Missao;
