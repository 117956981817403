import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.css"; // Importa o CSS com a customização da scrollbar
import Header from "./components/Header";
import SplashScreen from "./Screens/SplashScreen";
import { ThemeProvider } from 'styled-components';
import ScrollToTop from './components/ScrollToTop';
// Importações diretas
import Home from './components/Home';
import ProductPage from './components/Product';
import Equipe from './Screens/SobreNos/Equipe';
import Missao from './Screens/SobreNos/Missao';
import Contato from './components/Menu/Contato';
import Servicos from './components/Menu/Servicos';

// Páginas dos produtos
import FrenteLoja from './components/Menu/Services/FrenteLoja';
import FundoLoja from './components/Menu/Services/FundoLoja';
import Deposito from './components/Menu/Services/Deposito';
import Mobiliario from './components/Menu/Services/Mobiliario';

const theme = {
  fontFamily: 'Montserrat, sans-serif',
};

function App() {
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [menuVisible, setMenuVisible] = useState(true);
  const [is3DActive, setIs3DActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Controla a exibição da splash screen
  useEffect(() => {
    setTimeout(() => {
      setShowSplashScreen(false);
    }, 2000);
  }, []);

  // Controla a visibilidade do menu com base no modal e no 3D ativo
  useEffect(() => {
    if (isModalOpen || is3DActive) {
      setMenuVisible(false);
    } else {
      setMenuVisible(true);
    }
  }, [isModalOpen, is3DActive]);

  return (
    <div className="App">
      {showSplashScreen ? (
        <SplashScreen />
      ) : (
        <Router>
          <ScrollToTop />
          <Header menuVisible={menuVisible} setMenuVisible={setMenuVisible} />
          <ThemeProvider theme={theme}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/product/:productName/:subProductName?"
                element={
                  <ProductPage
                    setMenuVisible={setMenuVisible}
                    setIs3DActive={setIs3DActive}
                    setIsModalOpen={setIsModalOpen}
                  />
                }
              />
              <Route path="/equipe" element={<Equipe />} />
              <Route path="/servicos" element={<Servicos />} />
              <Route path="/contato" element={<Contato />} />
              {/* Rotas para os produtos */}
              <Route path="/produto/frente-loja" element={<FrenteLoja />} />
              <Route path="/produto/fundo-loja" element={<FundoLoja />} />
              <Route path="/produto/deposito" element={<Deposito />} />
              <Route path="/produto/mobiliario" element={<Mobiliario />} />
            </Routes>
          </ThemeProvider>
        </Router>
      )}
    </div>
  );
}

export default App;
