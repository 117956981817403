import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ExpositoresSection = ({ tipo }) => {
    // Definir os arrays de produtos
    const produtosFrenteLoja = [
        {
            id: 1,
            nome: "Expositor Porto Viseu",
            descricao: "Design moderno e eficiente.",
            imagem: "https://storage.googleapis.com/videos-aer/Design/Expositor/2.png",
            link: "/product/EXPOSITOR PORTO VISEU",
        },
        {
            id: 2,
            nome: "Vitrine Porto Paris",
            descricao: "Ideal para vitrines elegantes.",
            imagem: "https://storage.googleapis.com/videos-aer/Design/Vitrines/PP/Design1.png",
            link: "/product/VITRINE PORTO PARIS",
        },
        {
            id: 3,
            nome: "Vitrine Porto Galicia",
            descricao: "Perfeito para exibir produtos com estilo.",
            imagem: "https://storage.googleapis.com/videos-aer/Design/Vitrines/PG/DesignPG3.png",
            link: "/product/VITRINE PORTO GALICIA",
        },
    ];

    const produtosFundoLoja = [
        {
            id: 4,
            nome: "Balcão Porto Horizontal",
            descricao: "Funcionalidade e espaço compacto para organização eficiente.",
            imagem: "https://storage.googleapis.com/videos-aer/Design/Balcao/boxhz/boxhz3.png",
            link: "/product/BALCAO PORTO HORIZONTAL",
        },
        {
            id: 5,
            nome: "Balcão Porto Vertical",
            descricao: "Design versátil e otimização de espaço vertical.",
            imagem: "https://storage.googleapis.com/videos-aer/Design/Balcao/boxvt/boxvt3.png",
            link: "/product/BALCAO PORTO VERTICAL",
        },
        {
            id: 6,
            nome: "Buffet",
            descricao: "Solução elegante para exposição e armazenamento de alimentos.",
            imagem: "https://storage.googleapis.com/videos-aer/Design/Mobiliario/Buffet/1.png",
            link: "/product/BUFFET",
        },
    ];

    const produtosEstantes = [
        {
            id: 7,
            nome: "Estante Depósito",
            descricao: "Robustez e praticidade para armazenar grandes volumes.",
            imagem: "https://storage.googleapis.com/videos-aer/Design/Estantes/Deposito/design3.png",
            link: "/product/DEPOSITO",
        },
        {
            id: 8,
            nome: "Estante Estocagem",
            descricao: "Ideal para organizar e acessar seus produtos com facilidade.",
            imagem: "https://storage.cloud.google.com/videos-aer/Design/Estantes/Estocagem/3.png",
            link: "/product/ESTOCAGEM",
        },
        {
            id: 9,
            nome: "Estante Cremalheiras",
            descricao: "Versatilidade e ajuste prático para produtos variados.",
            imagem: "https://storage.googleapis.com/videos-aer/Design/Estantes/Cremalheiras/1.png",
            link: "/product/CREMALHEIRAS",
        },
    ];

    const produtosMobiliario = [
        
        {
            id: 11,
            nome: "Mesa Gaveteiro",
            descricao: "Organização e funcionalidade em um único móvel.",
            imagem: "https://storage.googleapis.com/videos-aer/Design/Mobiliario/MesaGav/1.png",
            link: "/product/MESA GAVETEIRO",
        },
        {
            id: 12,
            nome: "Mesa com Pia",
            descricao: "Solução prática e funcional para limpeza e preparo.",
            imagem: "https://storage.googleapis.com/videos-aer/Design/Mobiliario/Mesacmpia/1.png",
            link: "/product/MESA COM PIA",
        },
        {
            id: 13,
            nome: "Lavadora",
            descricao: "Solução eficiente para higienização e limpeza com praticidade.",
            imagem: "https://storage.googleapis.com/videos-aer/Design/Mobiliario/Lavadora/1.png",
            link: "/product/LAVADORA",
        }
        
    ];



    // Selecionar produtos com base no tipo
    let produtos;
    switch (tipo) {
        case "frente-loja":
            produtos = produtosFrenteLoja;
            break;
        case "fundo-loja":
            produtos = produtosFundoLoja;
            break;
        case "estantes":
            produtos = produtosEstantes;
            break;
        case "mobiliario":
            produtos = produtosMobiliario;
            break;
        default:
            produtos = []; // Retorna uma lista vazia se o tipo não for reconhecido
            break;
    }


    return (
        <ExpositoresContainer>
            <SectionTitle>Produtos Relacionados</SectionTitle>
            <ProdutosGrid>
                {produtos.map((produto) => (
                    <ProdutoCard key={produto.id}>
                        <ProdutoImagem src={produto.imagem} alt={produto.nome} />
                        <ProdutoNome>{produto.nome}</ProdutoNome>
                        <ProdutoDescricao>{produto.descricao}</ProdutoDescricao>
                        <ProdutoLink to={produto.link}>Visitar</ProdutoLink>
                    </ProdutoCard>
                ))}
            </ProdutosGrid>
        </ExpositoresContainer>
    );
};

export default ExpositoresSection;

// Estilos
// Responsividade ajustada sem remover as fontes ou configurações existentes

const ExpositoresContainer = styled.section`
    margin-top: 100px;
    padding: 100px 50px;
    background: linear-gradient(to bottom, #f5f7fa, #e4e7eb);
    min-height: 800px;

    @media (max-width: 768px) {
        padding: 50px 20px;
        min-height: auto;
    }
`;

const SectionTitle = styled.h2`
    text-align: center;
    font-size: 2.5rem;
    color: #354A81;
    margin-bottom: 80px;
    font-family: 'Brmalls'; /* Mantida a fonte personalizada */
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 2rem;
        margin-bottom: 40px;
    }
`;

const ProdutosGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    justify-items: center;

    & > div:nth-child(2) {
        transform: translateY(-20px);
    }

    & > div:nth-child(1),
    & > div:nth-child(3) {
        transform: translateY(20px);
    }

    @media (max-width: 768px) {
        gap: 20px;
        & > div:nth-child(1),
        & > div:nth-child(3),
        & > div:nth-child(2) {
            transform: none; /* Remove o deslocamento em telas menores */
        }
    }
`;

const ProdutoCard = styled.div`
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 20px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            transform: translateY(-15px);
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
        }

        &:nth-child(2):hover {
            transform: translateY(-35px);
        }

        &:nth-child(1):hover,
        &:nth-child(3):hover {
            transform: translateY(5px);
        }
    }

    &:nth-child(2) {
        transform: translateY(-20px);
    }

    &:nth-child(1),
    &:nth-child(3) {
        transform: translateY(20px);
    }

    @media (max-width: 768px) {
        padding: 15px;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            transform: none; /* Remove deslocamentos no mobile */
        }
    }
`;


const ProdutoImagem = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 15px 15px 0 0;

    @media (max-width: 768px) {
        height: 150px;
    }
`;

const ProdutoNome = styled.h3`
    font-size: 1.5rem;
    color: #222;
    margin: 15px 0;
    font-family: 'Brmalls'; /* Mantida a fonte personalizada */
    font-weight: 600;

    @media (max-width: 768px) {
        font-size: 1.2rem;
        margin: 10px 0;
    }
`;

const ProdutoDescricao = styled.p`
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
    font-family: 'Brmalls'; /* Mantida a fonte personalizada */

    @media (max-width: 768px) {
        font-size: 0.9rem;
        margin-bottom: 15px;
    }
`;

const ProdutoLink = styled(Link)`
    display: inline-block;
    background-color: transparent;
    color: #354A81;
    border: 2px solid #354A81;
    text-decoration: none;
    padding: 12px 25px;
    border-radius: 25px;
    font-size: 1rem;
    font-family: 'Brmalls'; /* Mantida a fonte personalizada */
    font-weight: bold;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: #354A81;
        color: #fff;
        border-color: #354A81;
    }

    @media (max-width: 768px) {
        font-size: 0.9rem;
        padding: 10px 20px;
    }
`;

