import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom'; // Importa o hook

const ClientsPartnersSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const navigate = useNavigate(); // Inicializa o hook

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.3 } // Ativa a animação quando 30% da seção está visível
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const clients = [
    {
      name: "Big bread",
      image: "/images/clients/bigbread.png",
      link: "https://www.instagram.com/bigbreadipiranga/"
    },
    {
      name: "Cepam",
      image: "/images/clients/cepam.jpg",
      link: "https://www.instagram.com/padocacepam/?hl=pt"
    },
    {
      name: "Carlo´s Bakery",
      image: "/images/clients/carlos.png",
      link: "https://carlosbakery.com.br/"
    },
    {
      name: "Padaria Colonial",
      image: "/images/clients/colonial.jpg",
      link: "https://www.instagram.com/colonialpadariaoficial/"
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    customPaging: i => <Dot />,
  };

  return (
    <SectionContainer ref={sectionRef}>
      <AnimatedSliderContainer
        initial={{ opacity: 0 }}
        animate={isVisible ? { opacity: 1 } : {}}
        transition={{ duration: 1.5, ease: 'easeOut' }}
      >
        <Slider {...settings}>
          {clients.map((client, index) => (
            <ClientCard key={index}>
              <a href={client.link} target="_blank" rel="noopener noreferrer">
                <ClientImage src={client.image} alt={client.name} />
                <ClientName>{client.name}</ClientName>
              </a>
            </ClientCard>
          ))}
        </Slider>
      </AnimatedSliderContainer>
      <RightSection>
        <AnimatedBlueBox
          initial={{ x: '100%', opacity: 0 }}
          animate={isVisible ? { x: '0%', opacity: 1 } : {}}
          transition={{ duration: 1, ease: 'easeOut' }}
        >
          <BigText>VEJA NOSSOS CLIENTES</BigText>
          {/* Adicionando a navegação no botão */}
          <ActionButton onClick={() => navigate('/servicos')}>
            SERVIÇOS <span>+</span>
          </ActionButton>
        </AnimatedBlueBox>
      </RightSection>
    </SectionContainer>
  );
};

export default ClientsPartnersSection;

// Styled Components
const SectionContainer = styled.div`
  display: flex;
  position: relative;
  background-color: #000;
  color: #fff;
  padding: 50px 0;
  height: 35vh; /* Ajuste para conteúdo dinâmico */
  justify-content: space-between;
  align-items: stretch; /* Mantém o alinhamento vertical */
  
  /* Tela responsiva */
  @media (max-width: 768px) {
    flex-direction: column; /* Troca para coluna em telas menores */
    padding: 0; /* Remove padding extra */
    height: 50vh; /* Limita a altura ao tamanho da tela */
  }
`;


const AnimatedSliderContainer = styled(motion.div)`
  flex: 3;
  max-width: 70%;
  overflow: visible;

  @media (max-width: 768px) {
    max-width: 100%; /* Expande para ocupar toda a largura no mobile */
    margin-bottom: 20px; 
    padding: 20px;
  }
`;

const ClientCard = styled.div`
  text-align: center;
  margin: 10px;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const ClientImage = styled.img`
  width: 100px;
  height: 100px;
  margin: 0 auto;
`;

const ClientName = styled.h3`
  font-size: 1.1rem;
  margin-top: 10px;
  color: #fff;
  font-family: 'Brmalls';
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%; 
    margin-top: 20px; 
  }
`;

const AnimatedBlueBox = styled(motion.div)`
  background-color: #354A81;
  width: 28%;
  height: 60%;
  padding: 20px;
  text-align: left;
  position: absolute;
  right: 0;
  top: 15%;
  transform: translateY(-50%);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

@media (max-width: 768px) {
  position: relative;
  width: 120%; /* Faz com que o box ocupe toda a largura */
  height: auto; /* Ajusta a altura dinamicamente ao conteúdo */
  padding: 20px; /* Mantém o padding interno */
  justify-content: center; 
  align-items: center;
  text-align: center;
  margin: 0; /* Remove margens extras */
  margin-top: -200px
}


    h2, /* BigText */
    button { /* ActionButton */
      margin: 10px 0; /* Espaçamento entre os elementos */
      font-family: 'Brmalls';
    }
  }
`;



const BigText = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
  margin-left: 15px;
  color: #fff;
  font-family: 'Ginza Heavy', sans-serif;
  font-weight: 800; /* Aumentar a grossura da fonte */
  letter-spacing: -0.5px;
`;

const Dot = styled.div`
  width: 7px;
  height: 7px;
  background-color: #bbb;
  border-radius: 50%;
  margin: 0 15px;
  transition: background-color 0.3s ease;
  margin-top: 15px;
  &.slick-active {
    background-color: #fff;
  }
`;

const ActionButton = styled.button`
  background: #fff;
  color: #000;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  font-family: 'Brmalls';
  span {
    font-size: 1rem;
    font-family: 'Brmalls';
    margin-left: 10px;
  }
`;
