import React from 'react';
import { Link } from 'react-router-dom'; // Importar o Link do react-router-dom
import styled from 'styled-components';
import { motion } from 'framer-motion'; // Importando Framer Motion
import Typewriter from 'typewriter-effect';
import { useNavigate } from 'react-router-dom';


const Servicos = () => {

  const variants = {
    hidden: { opacity: 0, y: 50 }, // Estado inicial
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } }, // Estado final
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, delay: i * 0.2 }, // Delay dinâmico baseado no índice
    }),
  };

  const imageVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: { scale: 1, opacity: 1, transition: { duration: 0.8, ease: 'easeOut' } },
  };

  const navigate = useNavigate(); // Inicializa o hook useNavigate
  const handleContactClick = () => {
    navigate('/contato'); // Redireciona para a rota "/contato"
  };


  return (
    <Container>
      {/* Seção "Serviços" */}
      <Section2
        as={motion.section} // Aplicando animação com Framer Motion
        id="historia"
        initial="hidden"
        whileInView="visible" // Ativa a animação quando o elemento está visível
        viewport={{ once: true, amount: 0.5 }} // Executa apenas uma vez, quando 50% da seção está visível
        variants={variants}
      >
        <BackgroundImage key={window.location.pathname}>
          <Overlay />
          <Content>
            <Title>Serviços</Title>
            <Breadcrumb>
              <BreadcrumbItem>
                <StyledLink to="/">Home</StyledLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator>{'>'}</BreadcrumbSeparator>
              <BreadcrumbItem active>Serviços</BreadcrumbItem>
            </Breadcrumb>
          </Content>
        </BackgroundImage>

      </Section2>

      {/* Nova Seção com texto e imagem */}
      <Section
        as={motion.section}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
      >
        <TextContainer>
          <Subtitle as={motion.p} custom={0} variants={textVariants}>
            Especialistas em Refrigeração
          </Subtitle>
          <MainTitle as={motion.h2} custom={1} variants={textVariants}>
            Soluções Confiáveis para o Seu Negócio
          </MainTitle>
          <Description as={motion.p} custom={2} variants={textVariants}>
            A AER Refrigeração oferece serviços de instalação, manutenção e reparos de equipamentos,
            garantindo eficiência e preservação dos seus produtos. Confie em nossa experiência para manter
            seu negócio sempre funcionando no melhor desempenho.
          </Description>
          <GetStartedButton
            as={motion.button}
            custom={3}
            variants={textVariants}
            onClick={handleContactClick} // Redireciona ao clicar
          >
            Entre em Contato
          </GetStartedButton>

        </TextContainer>
        <ImageContainer>
          <CircleImageContainer as={motion.div} variants={imageVariants}>
            <CircleImage src="images/ursoazul.png" alt="Cooking Class" />

          </CircleImageContainer>
          <VerticalText>
            <Typewriter
              options={{
                strings: ['Soluções'], // Texto a ser digitado
                autoStart: true,       // Começa automaticamente
                loop: false,           // Não repete
                delay: 75,             // Velocidade de digitação (75ms por letra)
                cursor: '',            // Remove o cursor piscante após a digitação
              }}
              onInit={(typewriter) => {
                typewriter
                  .typeString('Soluções')
                  .callFunction(() => {
                    const cursor = document.querySelector('.Typewriter__cursor');
                    if (cursor) {
                      cursor.style.display = 'none';
                    }
                  })
                  .start();
              }}

            />
          </VerticalText>


        </ImageContainer>
      </Section>

      {/* Seção dos itens */}
      <ItemsSection>

        <ItemCard>
          <StyledLink to="/produto/frente-loja">
            <ItemImage src="images/image/Servicos/frente-loja.jpg" alt="Frente de Loja" />
            <CardHeader>Exposição Perfeita</CardHeader>
            <ItemContent>
              <ItemTitle>Frente de Loja</ItemTitle>
              <ItemLocation>Elegância em Vitrines e Expositores</ItemLocation>
            </ItemContent>
          </StyledLink>
        </ItemCard>



        <ItemCard>
          <StyledLink to="/produto/fundo-loja">
            <ItemImage src="images/image/Servicos/fundo-loja.jpg" alt="Fundo de Loja" />
            <CardHeader>Refrigeração Inteligente</CardHeader>
            <ItemContent>
              <ItemTitle>Fundo de Loja</ItemTitle>
              <ItemLocation>Balcões Horizontal e Vertical</ItemLocation>
            </ItemContent>
          </StyledLink>
        </ItemCard>



        <ItemCard>
          <StyledLink to="/produto/deposito">
            <ItemImage src="images/image/Servicos/deposito.jpg" alt="Depósito" />
            <CardHeader>Espaço Otimizado</CardHeader>
            <ItemContent>
              <ItemTitle>Depósito</ItemTitle>
              <ItemLocation>Estantes para Armazenamento</ItemLocation>
            </ItemContent>
          </StyledLink>
        </ItemCard>



        <ItemCard>
          <StyledLink to="/produto/mobiliario">
            <ItemImage src="images/image/Servicos/inox.jpg" alt="Mobiliário" />
            <CardHeader>Design em Aço</CardHeader>
            <ItemContent>
              <ItemTitle>Mobiliário</ItemTitle>
              <ItemLocation>Excelência em Inox</ItemLocation>
            </ItemContent>
          </StyledLink>
        </ItemCard>

      </ItemsSection>


    </Container>
  );
};

// Estilos

const Container = styled.div`
  font-family: 'Arial, sans-serif';
  color: #000;
`;

const Section2 = styled.section`
  position: relative;
`;

const BackgroundImage = styled.div`
  position: relative;
  background-image: url('/images/image/Servicos/servicos.jpg');
  background-size: cover;
  background-position: center;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: 30vh;
  }

  @media (max-width: 480px) {
    height: 25vh;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  color: #fff;
`;

const Title = styled.h1`
  font-size: 4rem;
  margin-bottom: 10px;
  font-weight: bold;
  color: #fff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 480px) {
    font-size: 2.5rem;
  }
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 140px;
  margin-left: 180px;
  margin-top: 40px;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 40px;
    margin-left: 0;
    gap: 20px;
  }

  @media (max-width: 480px) {
    padding: 20px;
    gap: 15px;
  }
`;

const TextContainer = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Subtitle = styled.p`
  font-size: 1rem;
  color: #888;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 6px;
  font-family: 'Brmalls';

  @media (max-width: 768px) {
    text-align: center;
    margin-left: 0;
  }
`;

const MainTitle = styled.h2`
  font-size: 3rem;
  color: #333;
  margin-bottom: 20px;
  text-align: left;
  font-family: 'Brmalls';

  @media (max-width: 768px) {
    font-size: 2.5rem;
    text-align: center;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: left;
  font-family: 'Brmalls';

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const GetStartedButton = styled(Link)`
  background-color: #354A81;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  display: block;
  margin-left: 0;
  font-family: 'Brmalls';
  text-decoration: none;
  text-align: center;

  @media (max-width: 768px) {
    padding: 10px 15px;
    font-size: 0.9rem;
    margin-left: 120px;
  }
`;


const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const CircleImageContainer = styled.div`
  width: 300px; /* Largura do círculo */
  height: 300px; /* Altura do círculo, igual à largura */

  overflow: hidden; /* Garante que a imagem não ultrapasse o círculo */
  display: flex; /* Alinha a imagem dentro do círculo */
  justify-content: center;
  align-items: center;
  /
  margin: 0 auto; /* Centraliza horizontalmente */

  @media (max-width: 768px) {
    width: 250px;
    height: 250px;
  }

  @media (max-width: 480px) {
    width: 200px;
    height: 200px;
  }
`;

const CircleImage = styled.img`
  width: 100%; /* Ajusta a imagem para ocupar todo o contêiner */
  height: 55.5%; /* Garante que a imagem ocupe toda a altura */
  object-fit: cover; /* Preenche o contêiner sem distorcer a proporção */
  position: relative; /* Garante que a imagem respeite os limites do contêiner */
  
`;



const ItemsSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  padding: 50px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 20px;
  }
`;

const ItemCard = styled.div`
  position: relative;
  background-color: #fff;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CardHeader = styled.div`
  position: absolute;
  top: 10px;
  left: 120px;
  background-color: #354A81;
  color: #fff;
  font-size: 0.8rem;
  padding: 5px 5px;
  border-radius: 3px;
  font-family: 'Brmalls';

  @media (max-width: 768px) {
    font-size: 0.7rem;
    top: 12px;
    left: 10px;
  }
`;

const ItemImage = styled.img`
  width: 70%;
  height: 450px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
  }

  @media (max-width: 480px) {
    height: 200px;
  }
`;

const ItemContent = styled.div`
  padding: 15px;
  text-align: center;

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const ItemTitle = styled.h3`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 5px;
  font-family: 'Brmalls';

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const ItemLocation = styled.p`
  font-size: 1rem;
  color: #555;
  font-family: 'Brmalls';

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const Breadcrumb = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #fff;
`;

const BreadcrumbItem = styled.span`
  color: ${(props) => (props.active ? '#354A81' : '#fff')};
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  font-family: 'Brmalls';
`;

const BreadcrumbSeparator = styled.span`
  margin: 0 5px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block; /* Garante que o link envolva todo o card */
`;

const VerticalText = styled.div`
  position: absolute;
  bottom: 35%;
  right: 25px;
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  text-orientation: mixed;
  white-space: nowrap;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.8rem;

  &::before {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    width: 80%;
    height: 0.5px;
    background-color: rgba(255, 255, 255, 0.3);
  }

  @media (max-width: 768px) {
    display: none; /* Oculta o texto vertical em telas menores */
  }
`;


export default Servicos;
