import React from 'react';
import styled from 'styled-components';

const ClientsSection = () => {
  const clients = [
    { id: 7, name: 'Essen Boulangerie', logo: '/images/clients/essen.jpg' },
    { id: 8, name: 'Santa Clara', logo: '/images/clients/santaclara.jpg' },
    { id: 2, name: 'Big Bread', logo: '/images/clients/bigbread.png' },
    { id: 4, name: 'Colonial', logo: '/images/clients/colonial.jpg' },
    { id: 6, name: 'Monte Carlo', logo: '/images/clients/montecarlo.png' },
    { id: 3, name: 'Cepam', logo: '/images/clients/cepam.jpg' },
    { id: 5, name: 'Dona Deôla', logo: '/images/clients/donadeola.png' },
    { id: 1, name: 'Carlos', logo: '/images/clients/carlos.png' },

  ];

  return (
    <Section>
      <Grid>
        {clients.map((client) => (
          <ClientCard key={client.id}>
            <Logo src={client.logo} alt={client.name} />
            <ClientName>{client.name}</ClientName>
          </ClientCard>
        ))}
      </Grid>
    </Section>
  );
};

export default ClientsSection;

// Styled Components
const Section = styled.section`
  text-align: center;
  padding: 40px 0px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Inicialmente, 4 colunas */
  gap: 20px; /* Espaçamento entre itens */
  justify-items: center; /* Centraliza os itens na célula */
  margin-top: 50px;

  /* Responsividade */
  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr); /* 3 colunas para telas médias */
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr); /* Mantém 3 colunas para tablets pequenos */
    gap: 15px; /* Reduz o espaçamento */
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(3, 1fr); /* 3 colunas em celulares */
    gap: 10px; /* Espaçamento menor */
  }
`;

const ClientCard = styled.div`
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);

  }
`;

const Logo = styled.img`
  width: 120px; /* Tamanho inicial */
  height: auto;
  margin-bottom: 8px; /* Espaçamento entre logo e texto */
  filter: grayscale(100%); /* Logos em tons de cinza */
  transition: filter 0.3s ease;

  &:hover {
    filter: grayscale(0%); /* Exibe as cores ao passar o mouse */
  }

  /* Responsividade */
  @media (max-width: 1024px) {
    width: 100px; /* Reduz o tamanho das imagens em telas médias */
  }
  @media (max-width: 768px) {
    width: 90px; /* Imagens menores em tablets */
  }
  @media (max-width: 480px) {
    width: 80px; /* Imagens menores para celulares */
  }
`;

const ClientName = styled.h3`
  font-size: 0.9rem; /* Tamanho inicial */
  font-weight: normal;
  color: #777;
  margin-top: 5px; /* Espaçamento entre logo e texto */
  font-family: 'Brmalls';

  /* Responsividade */
  @media (max-width: 1024px) {
    font-size: 0.85rem; /* Reduz tamanho do texto em telas médias */
  }
  @media (max-width: 768px) {
    font-size: 0.75rem; /* Texto menor em tablets */
  }
  @media (max-width: 480px) {
    font-size: 0.7rem; /* Texto ainda menor para celulares */
  }
`;
