import React, { useRef, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Section from './Section';
import VideoBackground from './VideoBackground';
import Fade from 'react-reveal/Fade';
import SobreCarousel from './SobreCarousel';
import ClientsPartnersSection from '../Screens/Home/ClientsPartnersSection';  // Novo componente importado
import UpdatesSection from '../Screens/Home/UpdatesSection';
import Footer from '../Screens/Home/Footer';
import { FaPlay, FaChevronRight } from 'react-icons/fa';
import { FaPause } from "react-icons/fa6";

const Home = () => {
  const navigate = useNavigate();
  const productsSectionRef = useRef(null);
  const videoRef = useRef(null); // Referência para o vídeo

  const [videoUrl, setVideoUrl] = useState('https://storage.googleapis.com/videos-aer/Principal/vitrine.mp4');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [backgroundImage, setBackgroundImage] = useState('https://storage.googleapis.com/videos-aer/Principal/inicio.png');
  const [showDarkOverlayEnd, setShowDarkOverlayEnd] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Executa ao carregar e adiciona o listener para redimensionamento
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleExploreClick = () => {
    productsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleViewProduct = (category) => {
    let productName = '';
    switch (category) {
      case 'Balcões':
        productName = 'BALCÃO PORTO VERTICAL';
        break;
      //case 'Estantes':
      //productName = 'DEPOSITO';
      //break;
      case 'Expositores':
        productName = 'EXPOSITOR PORTO VISEU';
        break;
      case 'Vitrines':
        productName = 'VITRINE PORTO GALICIA';
        break;

        case 'Estantes':
          productName = 'CREMALHEIRAS';
          break;
      default:
        productName = ''; // Caso não encontre
    }

    if (productName) {
      // Rola a página para o topo
      window.scrollTo(0, 0);

      // Redireciona para a página do produto específico
      navigate(`/product/${productName}`);
    }
  };

  const handleArrowClick = () => {
    const section = productsSectionRef.current;
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  const handlePauseClick = () => {
    if (videoRef.current) {
      if (isPaused) {
        videoRef.current.play(); // Reproduz o vídeo se estiver pausado
      } else {
        videoRef.current.pause(); // Pausa o vídeo se estiver reproduzindo
      }
      setIsPaused(!isPaused); // Atualiza o estado para alternar o ícone
    }
  };



  return (
    <Container>
      <HeroSectionContainer>
        <HeroSection>
       
            <TextContainer isMobile={isMobile}>
              <Title isMobile={isMobile}>O Futuro É Nosso Presente</Title>
              <Button isMobile={isMobile} onClick={handleExploreClick}>
                Explorar
              </Button>
            </TextContainer>

         
        </HeroSection>

        <VideoBackgroundWrapper>
          {isMobile ? (
            <BackgroundImage src={backgroundImage} alt="Background" />
          ) : (
            <VideoBackgroundStyled
              ref={videoRef}
              src={videoUrl}
              autoPlay
              muted
              loop
              playsInline
              controls={false} // Oculta controles nativos do vídeo
            />


          )}


          <DarkOverlay />
          <BottomDarkOverlay />
          <DownArrow src="/images/down-arrow.svg" alt="Down Arrow" onClick={handleExploreClick} />
        </VideoBackgroundWrapper>
        <BlackOverlay />
        <PauseButton isMobile={isMobile} onClick={handlePauseClick}>
          <IconContainer>
            {isPaused ? <FaPlay color="white" /> : <FaPause color="white" />}
          </IconContainer>
        </PauseButton>
      </HeroSectionContainer>

      <FeaturedItemsSection>
        {featuredItems.map((item, index) => (
          <FeaturedItem key={index} style={{ backgroundImage: `url(${item.image})` }}>
            <Overlay />
            <FeaturedContent>
              <FeaturedText>{item.title}</FeaturedText>
              <ArrowIcon>
                <FaChevronRight />
              </ArrowIcon>
            </FeaturedContent>
          </FeaturedItem>
        ))}
      </FeaturedItemsSection>


      {/* Seção de Produtos */}
      <SectionContainer ref={productsSectionRef}>
        <Fade bottom>
          <SectionTitleDest>Produtos em Destaque</SectionTitleDest>
        </Fade>
        {sections.map((section, index) => (
          <WhiteBackground key={index}>
            <Row>
              {section.items.map((item, idx) => (
                <Fade bottom key={idx}>
                  <HalfSection>
                    <LogoContainer />
                    <ImageWrapper>
                      <ProductTitle>{item.title}</ProductTitle>
                      {/* Altera a imagem com base no estado isMobile */}
                      <ProductImage
                        src={isMobile ? item.mobileImage : item.image}
                        alt={item.title}
                      />
                      <GradientOverlay />
                      <TextWrapper>
                        <ProductDescription>{item.description}</ProductDescription>
                        <ButtonWrapper>
                          <ProductButtonFilled onClick={() => handleViewProduct(item.title)}>
                            {item.leftBtn}
                          </ProductButtonFilled>
                        </ButtonWrapper>
                      </TextWrapper>
                    </ImageWrapper>
                  </HalfSection>
                </Fade>
              ))}
            </Row>
          </WhiteBackground>
        ))}


      </SectionContainer>

      <SectionContainer1>
        <SobreCarousel />
      </SectionContainer1>

      {/* Clientes e Parceiros */}
      <SectionContainer2>
        <ClientsPartnersSection /> {/* Novo componente aqui */}
      </SectionContainer2>

      {/* Atualizações */}
      <Fade bottom>
        <SectionContainer3>
          <UpdatesSection />
        </SectionContainer3>
      </Fade>

      {/* Rodapé com Contato */}
      <SectionContainer4>
        <Footer />
      </SectionContainer4>
    </Container>
  );
};

export default Home;


// Styled Components and Animations


const fadeOutOverlay = keyframes`
  0% {
    opacity: 1;  // Começa com o fundo completamente preto
  }
  100% {
    opacity: 0;  // Fica totalmente transparente, revelando o vídeo
  }
`;

// Defina a animação de fade-in suave para o vídeo
const fadeInVideo = keyframes`
  0% {
    opacity: 0;  // Começa invisível
  }
  100% {
    opacity: 1;  // Termina completamente visível
  }
`;

const fadeInAndBounce = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  70% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeInOnly = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const animateDown = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  

`;

const HeroSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;  // Alinha o conteúdo na parte inferior
  align-items: flex-start;    // Alinha o conteúdo à esquerda
  height: 100vh;
  padding: 0 50px 100px;      // Espaçamento à esquerda e inferior
  color: white;
  text-align: left;
  z-index: 2;
`;


const HeroSectionContainer = styled.div`
  position: relative;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column; /* Empilha os elementos verticalmente */
  align-items: center; /* Centraliza os elementos horizontalmente */
  text-align: center; /* Centraliza o texto */
  font-family: 'Brmalls';
  z-index: 3;
  padding: 0 20px; /* Adiciona espaço lateral */
  position: relative;

  animation: ${({ isMobile }) => (isMobile ? fadeInOnly : fadeInOnly)} 2s ease;

  @media (max-width: 768px) {
    margin-top: 20vh; /* Ajusta a posição no mobile */
    gap: 20px; /* Espaçamento entre o título e o botão */
  }
`;






const SmallTitle = styled.h3`
  font-size: 1rem;
  margin-bottom: 10px;
  font-family: 'Brmalls';

  @media (max-width: 768px) {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
`;

const Title = styled.h1`
  font-size: 3.5rem; /* Tamanho padrão */
  font-family: 'Brmalls';
  margin-bottom: 20px; /* Espaçamento padrão abaixo */
  text-transform: capitalize;
  letter-spacing: -1px;
  font-weight: 600;
  margin-right: -560px;
  animation: ${({ isMobile }) => (isMobile ? fadeInOnly : fadeInOnly)} 2s ease;

  @media (max-width: 768px) {
    font-size: 2rem; /* Tamanho do texto no mobile */
    text-align: center; /* Centralize o texto no mobile */
    margin: 0; /* Remova margens extras */
    margin-bottom: 550px
  }
`;




const VideoBackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  overflow: hidden;
  background-color: black;
`;

const VideoBackgroundStyled = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 768px) {
    object-fit: cover;
  }
`;


const BlackOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;  // Começa com um fundo preto
  z-index: 1;  // Fica acima do vídeo
  pointer-events: none;
  animation: ${fadeOutOverlay} 2s ease-in-out forwards;  // Aplica a animação de fade-out
`;

const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1.2) 100%);
`;

const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Escurece o fundo para destacar o texto */
  z-index: 1;
  pointer-events: none; /* Isso garante que ele não bloqueie cliques */
`;

const Button = styled.div`
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 15px 40px; /* Espaçamento padrão */
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  font-family: 'Brmalls';
  text-transform: capitalize;
  margin-top: 10px; /* Espaçamento entre título e botão */
  z-index: 5;
  

  animation: ${({ isMobile }) => (isMobile ? fadeInOnly : fadeInOnly)} 2s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 768px) {
    padding: 10px 20px; /* Ajuste o padding no mobile */
    font-size: 0.9rem; /* Tamanho da fonte menor no mobile */
    margin-top: 10px; /* Espaçamento consistente */
  }
`;


const DownArrow = styled.img`
  height: 50px;
  position: absolute;
  bottom: 20px; /* Posiciona a seta perto da parte inferior da tela */
  left: 48.9%;
  transform: translateX(-50%);
  animation: ${animateDown} infinite 1.5s;
  z-index: 10;
  cursor: pointer;
  pointer-events: auto; /* Permite cliques */

  @media (max-width: 768px) {
    display: none; /* Oculta o DownArrow em resoluções de celular */
  }
`;


const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centraliza o conteúdo verticalmente */
  align-items: center; /* Centraliza o conteúdo horizontalmente */
  width: 100%;
  margin-top: 50px; /* Diminui a margem superior */
  font-family: 'Brmalls';
`;

const SectionContainer1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centraliza o conteúdo verticalmente */
  align-items: center; /* Centraliza o conteúdo horizontalmente */
  width: 100%;
  margin-top: 120px; /* Diminui a margem superior */
  font-family: 'Brmalls';
`;




const SectionContainer2 = styled.div`
`;
const SectionContainer3 = styled.div`
  margin-top: 120px;

`;

const SectionContainer4 = styled.div`
  margin-top: 5px;
`;

const WhiteBackground = styled.div`
  background-color: white;
  padding: 50px 0;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 colunas para desktop */
  gap: 20px;
  grid-row-gap: 40px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Altera para 1 coluna em telas menores */
    padding: 10px;
  }
`;

const SectionTitleDest = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  margin-top: 60px;

  margin-bottom: 40px; /* Adiciona um espaçamento maior entre o título e os produtos */
  font-family: 'Brmalls';
`;


const HalfSection = styled.div`
  position: relative;
  width: 580px;
  height: 820px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin: 20px;
  text-align: center;

  @media (max-width: 768px) {
    width: 90%; /* Ajusta a largura para quase toda a tela em dispositivos móveis */
    height: auto; /* Ajusta a altura automaticamente */
    margin: 10px auto; /* Centraliza o item na tela */
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease; /* Suaviza o efeito de zoom */

  &:hover {
    transform: scale(1.05); /* Aumenta a imagem em 5% ao passar o mouse */
  }
     @media (max-width: 768px) {
    object-fit: contain; /* Ajuste para dispositivos móveis */
  }
`;


const TextWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px; /* Alinha o texto e botão mais para a esquerda */
  width: 100%;
  color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Garante o alinhamento à esquerda */

   @media (max-width: 768px) {
    align-items: center; /* Garante o alinhamento à esquerda */
    text-align: center;
    margin-left: -2px;
  }
`;

const ProductTitle = styled.h1`
  position: absolute;
  top: 20px;
  left: 20px;
  color: white;
  font-size: 1.2rem;

  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'Ginza Heavy', sans-serif;
  z-index: 2;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const ProductDescription = styled.p`
  font-size: 1.2rem;
  margin: 10px 0;
  color: white;
  padding: 10px;
  font-family: 'Brmalls', sans-serif;
  margin-bottom: -5px;
 
  @media (max-width: 768px) {
    font-size: 1rem;
    margin-right: 10px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-left: 10px;

   @media (max-width: 768px) {
    margin-right: 20px;
  }
`;

const ProductButton = styled.button`
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  border: none;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Brmalls', sans-serif;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`;

const ProductButtonFilled = styled.button`
  background-color: transparent; /* Fundo transparente */
  color: white; /* Texto branco */
  border: 1px solid white; /* Borda branca */
  padding: 10px 20px; /* Espaçamento interno */
  border-radius: 5px; /* Bordas arredondadas */
  cursor: pointer;
  font-family: 'Brmalls', sans-serif;
  font-size: 1rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Leve fundo branco transparente no hover */
  }

  @media (max-width: 768px) {
    padding: 8px 16px; /* Menor espaçamento para dispositivos móveis */
    font-size: 0.9rem; /* Fonte reduzida */
  }
`;


const ProductButtonOutlined = styled.button`
  background-color: transparent; /* Fundo transparente */
  color: #fff; /* Texto branco */
  border: 2px solid #fff; /* Borda branca */
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Brmalls', sans-serif;
  font-size: 1rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Leve fundo branco transparente ao hover */
  }
`;

const BottomDarkOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%; /* Ajuste a altura para cobrir a área desejada */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)); /* Gradiente escuro que se dissipa para transparente */
  z-index: 1;
  pointer-events: none;
`;

const PauseButton = styled.button`
  position: absolute;
  bottom: 50px;
  right: 40px;
  background: transparent;
  border: 2px solid white;
  color: white;
  padding: 10px;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  cursor: pointer;
  z-index: 1005;

  animation: ${({ isMobile }) => (isMobile ? fadeInOnly : fadeInOnly)} 2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
    @media (max-width: 768px) {
    display: none; /* Remove o botão de pausa na responsividade */
  }
`;

const IconContainer = styled.div`
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FeaturedItemsSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px; /* Espaço entre os itens */
  margin: 80px 0;

  @media (max-width: 768px) {
    flex-direction: column; /* Altere a direção para coluna em celulares */
    gap: 40px; /* Reduza o espaço entre os itens */
    align-items: center; /* Faça os itens ocuparem toda a largura */
  }
`;


const FeaturedItem = styled.div`
  position: relative;
  width: 450px; /* Largura do item */
  height: 300px; /* Altura do item */
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease; /* Transição suave */

  &:hover {
    transform: scale(1.05); /* Efeito de leve aumento ao passar o mouse */
  }

   @media (max-width: 768px) {
    width: 400px; /* O item ocupa toda a largura disponível */
  }
`;

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px; /* Ajuste a altura do overlay conforme desejado */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  pointer-events: none;
`;

const FeaturedContent = styled.div`
  position: absolute;
  bottom: 28px; /* Sobe o texto e a seta acima do overlay */
  width: 100%;
  padding: 0 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: auto;
`;


const FeaturedText = styled.h3`
  font-size: 0.9rem; /* Ajusta o tamanho do texto */
  margin-top: 20px;
  margin: 0;
  font-weight: 400;
  font-family: 'Brmalls';
`;

const ArrowIcon = styled.div`
  font-size: 0.9rem;
  display: flex;
  align-items: center;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-image: url('/images/menu_images/urso.png'); // Insira o caminho do logotipo aqui
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 5;
`;

const featuredItems = [
  {
    title: 'Essencial para sua Padaria',
    image: '/images/menu_images/render.jpg',
  },
  {
    title: 'Serviços',
    image: '/images/menu_images/servicos2.jpg',
  },
  {
    title: 'O novo Expositor Viseu',
    image: '/images/menu_images/viseumenu.png',
  },
];

const sections = [
  {
    items: [
      {
        title: 'Vitrines',
        description: 'Design inovador para refrigerar e expor com eficiência.',
        image: '/images/menu_images/vitrine.png',
        mobileImage: '/images/menu_images/vitrine.png',
        leftBtn: 'Visualizar produto',
        rightBtn: 'Ver detalhes',
      },
      {
        title: 'Expositores',
        description: 'Elegante e acessível para destacar produtos.',
        image: '/images/menu_images/expositor.png',
        mobileImage: '/images/menu_images/expositorcelular.jpg',
        leftBtn: 'Visualizar produto',
        rightBtn: 'Ver detalhes',
      },
      {
        title: 'Balcões',
        description: 'Moderno e funcional para exibir produtos.',
        image: '/images/menu_images/box.png',
        mobileImage: '/images/menu_images/boxcelular.jpg',
        leftBtn: 'Visualizar produto',
        rightBtn: 'Ver detalhes',
      },
      {
        title: 'Estantes',
        description: 'Estrutura forte para armazenagem eficiente.',
        image: '/images/menu_images/cremalheira.png',
        mobileImage: '/images/menu_images/cremalheiracelular.jpg',
        leftBtn: 'Visualizar produto',
        rightBtn: 'Ver detalhes',
      },


    ],
  },
];


